import { LanguageListContainer, LanguageListLI, LanguageListLink, LanguageListUL } from "./LanguageList.styled";
import { useRef } from "react";
import { useOutside } from "../../../../../../hooks";
import { observer } from "mobx-react";
import { useStores } from "../../../../../../stores";
import { API } from "../../../../../../core";

interface LanguageListProps {
	show: boolean;
	onClose: () => void;
}

export const LanguageList = observer(({ show, onClose }: LanguageListProps) => {
	// Стор
	const { settingsStore, languagesStore } = useStores();

	// Реф для контейнера
	const containerRef = useRef<HTMLDivElement>(null);

	// Отслеживаем закрытие
	useOutside(containerRef, onClose);

	// Установка языка
	const handleOnSetLanguage = (language: string) => {
		// Сохраняем в localStorage
		API.saveLanguage = language;

		settingsStore.setLanguage(language);
		onClose();
	};

	return (
		<LanguageListContainer isShow={show} ref={containerRef}>
			<LanguageListUL>
				{languagesStore.list.map((languageStore) => {
					return (
						<LanguageListLI key={languageStore.code}>
							<LanguageListLink
								isActive={languageStore.code === settingsStore.language}
								onClick={() => handleOnSetLanguage(languageStore.code)}
							>
								{languageStore.code}
							</LanguageListLink>
						</LanguageListLI>
					);
				})}
			</LanguageListUL>
		</LanguageListContainer>
	);
});
