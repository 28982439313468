import { action, makeObservable, observable } from "mobx";
import { LanguageStore } from "./language.store";
import { BaseStore } from "../common";
import { Language } from "../../interfaces";

export class LanguagesStore extends BaseStore<Language, typeof LanguageStore> {
	PATH = "languages";

	constructor() {
		super(LanguageStore);
		makeObservable(this);
	}
}
