import * as React from "react";
import { ServiceListContainer, ServiceListDescription, ServiceListItem, ServiceListName } from "./ServiceList.styled";
import { ReactComponent as SchoolIcon } from "./school.svg";
import { ReactComponent as CollegeIcon } from "./college.svg";
import { ReactComponent as UniversityIcon } from "./university.svg";
import { ServiceName } from "../../../../constants";
import { useTranslation } from "react-i18next";

export const ServiceList = () => {
	const { t } = useTranslation();

	return (
		<ServiceListContainer>
			<ServiceListItem>
				<SchoolIcon />
				<ServiceListName service={ServiceName.School}>
					{t("pages.main.what_is_musicabinet.levels.list.beginner.title")}
				</ServiceListName>
				<ServiceListDescription>
					{t("pages.main.what_is_musicabinet.levels.list.beginner.description")}
				</ServiceListDescription>
			</ServiceListItem>

			<ServiceListItem>
				<CollegeIcon />
				<ServiceListName service={ServiceName.College}>
					{t("pages.main.what_is_musicabinet.levels.list.advanced.title")}
				</ServiceListName>
				<ServiceListDescription>
					{t("pages.main.what_is_musicabinet.levels.list.advanced.description")}
				</ServiceListDescription>
			</ServiceListItem>

			<ServiceListItem>
				<UniversityIcon />
				<ServiceListName service={ServiceName.University}>
					{t("pages.main.what_is_musicabinet.levels.list.professional.title")}
				</ServiceListName>
				<ServiceListDescription>
					{t("pages.main.what_is_musicabinet.levels.list.professional.description")}
				</ServiceListDescription>
			</ServiceListItem>
		</ServiceListContainer>
	);
};
