import "reset-css/reset.css";
import "@fontsource/montserrat";
import "@fontsource/montserrat/200.css";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { GlobalStyled } from "./styles";
import { RootStoreProvider } from "./stores";
import { ForgotModal, GrandChartModal, SignInModal, SignUpModal, UnitingModal, WelcomeModal } from "./components";
import { Notifications } from "./components/common/Notifications/Notifications";
import { ViewIframe } from "./components/ui/Other/ViewIrame/ViewIframe";
import { AccessLimitModal } from "./components/ui/Modals/AccessLimitModal/AccessLimitModal";

import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("mc_app") as HTMLElement);

root.render(
	/*<React.StrictMode>*/
	<RootStoreProvider>
		<BrowserRouter>
			<App />
			<GlobalStyled />

			<Notifications />
			<SignInModal />
			<SignUpModal />
			<ForgotModal />
			<GrandChartModal />
			<ViewIframe />
			<UnitingModal />
			{/*<TrialNotification />*/}

			<AccessLimitModal />
			<WelcomeModal />
		</BrowserRouter>
	</RootStoreProvider>,
	/*</React.StrictMode>,*/
);
