import * as React from "react";
import { useEffect, useState } from "react";
import { Container } from "./LessonPage.styled";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { Header } from "./Header/Header";
import { useStores } from "../../stores";
import { Body } from "./Body/Body";
import { API } from "../../core";
import { HEADER_TYPE, ServiceName, SETTINGS, SPACE_CONTROL, SPACE_CONTROL_CURRENT } from "../../constants";
import { AiResultModal, TutorialFrame, ViewerModal } from "../../components";
import { MessageRotate, NextLesson } from "../../components/common";

export const LessonPage = observer(() => {
	const {
		settingsStore,
		authStore,
		lessonStore,
		websocketStore,
		metronomeStore,
		lessonProgressStore,
		audioPlayerStore,
		viewerStore,
	} = useStores();
	const { uuid } = useParams();

	const [isShowContext, setIsShowContext] = useState(true);

	// Если сменился язык, обновляем страницу
	useEffect(() => {
		(async () => {
			// Обновляем урок
			await lessonStore.getByUUID(lessonStore.uuid);

			// Обновляем
			await lessonStore?.scores.currentScore.getVideo();
		})();
	}, [settingsStore.language]);

	// Подписываем на обновление результатов
	useEffect(() => {
		(async () => {
			if (websocketStore.connect) {
				websocketStore.addCallback("ai_finish", async () => {
					console.log("AI FINISH UPDATE", viewerStore.chooseItemID, viewerStore);
					const { list, chooseItemID } = viewerStore;

					// Если мы находимся в просмотре
					if (chooseItemID && list) {
						console.log("Enter for update");

						// Находим нужный нотный стан
						// @ts-ignore
						const findElement = list.items.find((item) => item.id === chooseItemID);

						console.log("findElement", findElement);

						// Если найден обновляем его
						if (findElement) {
							console.log("find update lesson", findElement);
							await lessonStore.getByUUID(lessonStore.uuid);

							// Обновляем ноты
							viewerStore.setList(lessonStore.scores.currentScore);
							viewerStore.setChooseItemId(chooseItemID);

							// Показываем
						}
					}
				});
			}
		})();

		return () => {};
	}, [websocketStore.connect]);

	useEffect(() => {
		(async () => {
			setIsShowContext(Boolean(new URLSearchParams(window.location.search).get("show_all")));

			settingsStore.setTypeHeader(HEADER_TYPE.RELATIVE);
			settingsStore.hideFooter();

			audioPlayerStore.onStop();

			// Получаем урок
			await lessonStore.getByUUID(String(uuid));

			// Получение статистики
			await lessonProgressStore.getList(
				lessonStore.grand_chart_group_lesson.grand_chart_id,
				lessonStore.grand_chart_group_lesson.grand_chart_course_id,
			);

			// Сохранение uuid
			localStorage.setItem(SETTINGS.UUID, String(uuid));

			// Установка трека
			if (lessonStore.currentService === ServiceName.College) {
				audioPlayerStore.setSelectedLibrary(lessonStore.charts.currentChart.firstLibrary);
			} else {
				audioPlayerStore.setSelectedLibrary(lessonStore.scores.currentScore.firstLibrary);
			}

			// Загружаем треки
			await audioPlayerStore.loadTrack();

			// Устанавливаем соединение с WS
			if (!websocketStore.connect) {
				await websocketStore.init();

				websocketStore.sendMessage({
					token: API.jwtToken,
					id: authStore.userStore.id,
					uuid: lessonStore.uuid,
					service_id: lessonStore.currentServiceId,
					grand_chart_id: lessonStore.grand_chart_group_lesson.grand_chart_id,
					grand_chart_course_id: lessonStore.grand_chart_group_lesson.grand_chart_course_id,
					grand_chart_module_id: lessonStore.grand_chart_group_lesson.grand_chart_module_id,
					grand_chart_group_lesson_id: lessonStore.grand_chart_group_lesson_id,
				});

				// Стартуем таймер
				lessonStore.timer.setIsStart(true);
			} else {
				websocketStore.sendMessage({
					token: API.jwtToken,
					id: authStore.userStore.id,
					uuid: lessonStore.uuid,
					service_id: lessonStore.currentServiceId,
					grand_chart_id: lessonStore.grand_chart_group_lesson.grand_chart_id,
					grand_chart_course_id: lessonStore.grand_chart_group_lesson.grand_chart_course_id,
					grand_chart_module_id: lessonStore.grand_chart_group_lesson.grand_chart_module_id,
					grand_chart_group_lesson_id: lessonStore.grand_chart_group_lesson_id,
				});

				// Стартуем таймер
				lessonStore.timer.setIsStart(true);
			}
		})();
	}, [uuid]);

	useEffect(() => {
		handleOnSpaceKey();

		return () => {
			audioPlayerStore.onStop();
		};
	}, []);

	useEffect(() => {
		// Disabled context menu
		if (!isShowContext) {
			//window.addEventListener("contextmenu", stopContext, false);
		}
	}, [isShowContext]);

	const handleOnSpaceKey = () => {
		document.body.onkeydown = function (event) {
			if (event.keyCode === 32) {
				event.preventDefault();
				const currentSpaceKeyControl = localStorage.getItem(SPACE_CONTROL_CURRENT) ?? SPACE_CONTROL_CURRENT;

				if (SPACE_CONTROL.PLAYER === currentSpaceKeyControl) {
					if (audioPlayerStore.isPlay) {
						audioPlayerStore.onStop();
					} else {
						audioPlayerStore.onPlay();
					}
				} else if (SPACE_CONTROL.METRONOME === currentSpaceKeyControl) {
					if (metronomeStore.isPlay) {
						metronomeStore.onStop();
					} else {
						metronomeStore.onStart();
					}
				} else {
					console.warn(`Not selected control key : SPACE_CONTROL_CURRENT`);
				}
			}
		};
	};

	useEffect(() => {
		(async () => {
			await metronomeStore.init();
		})();
	}, []);

	return (
		<>
			<Container theme={lessonStore.grand_chart_group_lesson.grand_chart_current.service.slug}>
				<Header />
				<Body />
				<ViewerModal theme={lessonStore.grand_chart_group_lesson.grand_chart_current.service.slug} />
				<NextLesson />
			</Container>
			<MessageRotate />
			<TutorialFrame />
			<AiResultModal />
		</>
	);
});
