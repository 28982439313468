import styled, { css } from "styled-components";
import { COLORS, fontMixins } from "../../../../../../styles";

interface LanguageListContainerProps {
	isShow: boolean;
}

export const LanguageListContainer = styled.div<LanguageListContainerProps>`
	position: absolute;
	top: 40px;
	left: 50%;
	transform: translateX(-50%);
	display: ${(props) => (props.isShow ? "flex" : "none")};
	width: 60px;
	padding: 10px 10px;
	background: ${COLORS.Black};
	border-radius: 8px;
`;

export const LanguageListUL = styled.ul`
	display: flex;
	flex: 1;
	flex-direction: column;
	row-gap: 5px;
`;

export const LanguageListLI = styled.li`
	display: flex;
	justify-content: center;
	border-bottom: 1px solid ${COLORS.Dark_Gray};
	padding-bottom: 5px;

	&:last-child {
		border-bottom: 0;
	}
`;

interface LanguageListLinkProps {
	isActive: boolean;
}

export const LanguageListLink = styled.button<LanguageListLinkProps>`
	display: flex;
	padding: 0;
	background: transparent;
	border: 0;
	color: ${COLORS.White};
	${fontMixins(15)};
	cursor: pointer;
	transition: all 0.2s;

	&:hover {
		color: ${COLORS.Gold};
	}

	${(props) =>
		props.isActive &&
		css`
			cursor: default;
			color: ${COLORS.Gold};
			font-weight: 600;
		`}
`;
