import * as React from "react";
import { ChartsContainer, ChartsSubTitleBlock, ChartsTitleBlock } from "./Charts.styled";
import { ChartsHeader } from "./ChartsHeader";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { ChartItem } from "./ChartItem";

interface ChartsProps {}

export const Charts = observer(({}: ChartsProps) => {
	const { lessonStore, viewerStore, audioPlayerStore } = useStores();
	const { title, items, sub_title } = lessonStore.charts.currentChart;

	const handleOnShow = async (idx: number) => {
		viewerStore.setList(lessonStore.charts.currentChart);
		viewerStore.setChooseItemId(idx);
		viewerStore.modal.open();

		// Загружаем треки
		await audioPlayerStore.loadTrack();
	};

	return (
		<>
			<ChartsContainer>
				<ChartsHeader />
				<ChartsTitleBlock>{title}</ChartsTitleBlock>
				{sub_title.length > 0 && <ChartsSubTitleBlock>{sub_title}</ChartsSubTitleBlock>}
				{items.map((chartItem, idx) => {
					return <ChartItem key={chartItem.id} order={chartItem.id} chartItem={chartItem} onClick={handleOnShow} />;
				})}
			</ChartsContainer>
		</>
	);
});
