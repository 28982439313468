import * as React from "react";
import {
	Actions,
	Container,
	LearningDescription,
	LearningIcon,
	LearningList,
	LearningListItem,
	LearningTitle,
	WhiteContainer,
} from "./WhatIsMusicabinet.styled";
import { ButtonStartTrial, Description, Title } from "../../../components/common";
import { ReactComponent as OneIcon } from "./LearningList/one.svg";
import { ReactComponent as TwoIcon } from "./LearningList/two.svg";
import { ReactComponent as ThreeIcon } from "./LearningList/three.svg";
import { VideoBlock } from "./VideoBlock/VideoBlock";
import { ServiceList } from "./ServiceList/ServiceList";
import { TitleDivider } from "../../../components/common/Title/Title.styled";
import { InstrumentList } from "./InstrumentList/InstrumentList";
import { SubTitle } from "../MainPage.styled";
import { useTranslation } from "react-i18next";

export const WhatIsMusicabinet = () => {
	const { t } = useTranslation();

	return (
		<WhiteContainer>
			<Container>
				<Title dangerouslySetInnerHTML={{ __html: t("pages.main.what_is_musicabinet.title") }}></Title>

				<TitleDivider />

				<SubTitle dangerouslySetInnerHTML={{ __html: t("pages.main.what_is_musicabinet.sub_title") }} />

				<Description
					size={"small"}
					dangerouslySetInnerHTML={{ __html: t("pages.main.what_is_musicabinet.description") }}
				/>

				<LearningList>
					<LearningListItem>
						<LearningIcon>
							<OneIcon />
						</LearningIcon>

						<LearningTitle>{t("pages.main.what_is_musicabinet.learning_list.titles.choose")}</LearningTitle>
						<LearningDescription
							dangerouslySetInnerHTML={{
								__html: t("pages.main.what_is_musicabinet.learning_list.descriptions.choose"),
							}}
						/>
					</LearningListItem>

					<LearningListItem>
						<LearningIcon>
							<TwoIcon />
						</LearningIcon>

						<LearningTitle>{t("pages.main.what_is_musicabinet.learning_list.titles.start")}</LearningTitle>
						<LearningDescription
							dangerouslySetInnerHTML={{ __html: t("pages.main.what_is_musicabinet.learning_list.descriptions.start") }}
						/>
					</LearningListItem>

					<LearningListItem>
						<LearningIcon>
							<ThreeIcon />
						</LearningIcon>

						<LearningTitle>{t("pages.main.what_is_musicabinet.learning_list.titles.play")}</LearningTitle>
						<LearningDescription
							dangerouslySetInnerHTML={{ __html: t("pages.main.what_is_musicabinet.learning_list.descriptions.play") }}
						/>
					</LearningListItem>
				</LearningList>

				<VideoBlock />

				<Title>{t("pages.main.what_is_musicabinet.choose_your_instrument.title")}</Title>
				<TitleDivider />
				<Description>{t("pages.main.what_is_musicabinet.choose_your_instrument.description")}</Description>

				<InstrumentList />

				<Title>{t("pages.main.what_is_musicabinet.levels.title")}</Title>
				<TitleDivider />
				<Description>{t("pages.main.what_is_musicabinet.levels.description")}</Description>
				<ServiceList />

				<Actions>
					<ButtonStartTrial />
				</Actions>
			</Container>
		</WhiteContainer>
	);
};
