import styled, { css } from "styled-components";
import { COLORS, fontMixins, phoneQuery } from "../../../../styles";
import { HEADER_TYPE } from "../../../../constants";

interface HeaderContainerI {
	header_type: HEADER_TYPE;
}

export const HeaderContainer = styled.header<HeaderContainerI>`
	position: ${(props) => props.header_type};
	top: 0;
	left: 0;
	z-index: 2000;
	width: 100%;
	grid-area: header;
	display: grid;
	grid-template-columns: 2fr auto;
	column-gap: 20px;
	align-items: center;
	padding: 10px 20px;

	${(props) =>
		props.header_type === HEADER_TYPE.ABSOLUTE &&
		css`
			background: linear-gradient(180deg, #040404 0%, rgba(4, 4, 4, 0) 100%);
		`}

	${(props) =>
		props.header_type === HEADER_TYPE.RELATIVE &&
		css`
			background: ${COLORS.Bgrnd};
		`}

  ${phoneQuery(css`
		grid-template-columns: 150px 1fr;
		column-gap: 0;
		padding-inline: 15px;
	`)}
`;

export const HeaderLeftSide = styled.div`
	display: grid;
	grid-template-columns: 150px 1px minmax(100px, auto);
	column-gap: 30px;
`;

export const HeaderMenuBar = styled.div``;

export const HeaderRightSide = styled.div`
	display: flex;
	column-gap: 50px;
	justify-content: right;
	align-items: center;

	${phoneQuery(css`
		grid-template-columns: auto minmax(50px, auto);
		column-gap: 10px;
	`)}
`;

export const HeaderBreadCrumbs = styled.div`
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	text-transform: uppercase;
	${fontMixins(18)};
	color: ${COLORS.White};

	div {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	span {
		min-width: 0;
		margin: 0 5px;
	}
`;
