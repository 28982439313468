import { action, makeObservable, observable } from "mobx";
import { Language } from "../../interfaces";

export class LanguageStore implements Language {
	@observable id = 0;
	@observable sort = 0;
	@observable name = "";
	@observable code = "";

	constructor(initialData: Language | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action
	fillStore(data: Language) {
		const { id, sort, name, code } = data;

		this.id = id;
		this.sort = sort;
		this.name = name;
		this.code = code;
	}
}
