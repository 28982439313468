import * as React from "react";
import { MottoContainer } from "./Motto.styled";
import { useTranslation } from "react-i18next";

interface MottoProps {}

export const Motto = ({}: MottoProps) => {
	const { t } = useTranslation();

	return <MottoContainer>{t("header.music_eduction_platform")}</MottoContainer>;
};
