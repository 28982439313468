import { action, makeObservable, observable } from "mobx";
import { HEADER_TYPE } from "../../constants";
import { ModalStore } from "../common";
import { API } from "../../core";

export class SettingsStore {
	@observable site = "";
	@observable type_header: HEADER_TYPE = HEADER_TYPE.ABSOLUTE;
	@observable hide_footer = false;
	@observable isLargeWidth = false;
	@observable welcomeModal = new ModalStore();
	@observable isWhiteMain = false;
	@observable language = API.language;

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setLanguage(value: string) {
		this.language = value;
	}

	@action.bound
	setTypeHeader(value: HEADER_TYPE) {
		this.type_header = value;
	}

	@action.bound
	showFooter() {
		this.hide_footer = false;
	}

	@action.bound
	hideFooter() {
		this.hide_footer = true;
	}

	@action.bound
	setIsLargeWidth(value: boolean) {
		this.isLargeWidth = value;
	}

	@action.bound
	setIsWhiteMain(value: boolean) {
		this.isWhiteMain = value;
	}
}
