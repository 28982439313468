import * as React from "react";
import { Container, LinkItem } from "./Menu.styled";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

export const Menu = observer(() => {
	const { t } = useTranslation();

	return (
		<Container>
			<LinkItem to={"/cabinet/my-day"}>{t("menu.day")}</LinkItem>
			<LinkItem to={"/cabinet/my-statistics"}>{t("menu.statistics")}</LinkItem>
			<LinkItem to={"/cabinet/my-subscriptions"}>{t("menu.subscriptions")}</LinkItem>
			<LinkItem to={"/cabinet/tutorials"}>{t("menu.tutorials")}</LinkItem>
			<LinkItem to={"/cabinet/notifications"}>{t("menu.notifications")}</LinkItem>
		</Container>
	);
});
