import * as React from "react";
import { Container, Link } from "./EditProfileButton.styled";
import { useNavigate } from "react-router-dom";
import { ICON_GEAR } from "../../../../../common/Icons";
import { useTranslation } from "react-i18next";

export const EditProfileButton = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleOnProfile = () => navigate("/cabinet/profile");

	return (
		<Container>
			<Link onClick={handleOnProfile}>
				<>
					<ICON_GEAR />
					{t("buttons.edit_profile")}
				</>
			</Link>
		</Container>
	);
};
