import { FilterBlock, FilterHeader, FilterItem, FiltersContainer, FilterTitle } from "./Filters.styled";
import { getColorByService } from "../../../styles";
import { FILTER_INSTRUMENTS, FILTER_LEVEL, FILTER_SUBSCRIPTIONS, ServiceName } from "../../../constants";
import { RadioCheck } from "../../../components/common";
import { useStores } from "../../../stores";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

interface FiltersProps {}

export const Filters = observer(({}: FiltersProps) => {
	const { t } = useTranslation();
	const { grandChartsMyDayStore, bannersStore } = useStores();

	useEffect(() => {
		(async () => {
			await grandChartsMyDayStore.getList();
		})();
	}, [grandChartsMyDayStore.filtersInstruments, grandChartsMyDayStore.filtersLevels]);

	return (
		<FiltersContainer isPadding={bannersStore.list.length > 0}>
			<FilterHeader>{t("filters.filter")}</FilterHeader>

			<FilterBlock>
				<FilterTitle>{t("filters.subscriptions")}:</FilterTitle>

				<FilterItem onClick={() => grandChartsMyDayStore.setFiltersSubscriptions(FILTER_SUBSCRIPTIONS.ALL)}>
					<RadioCheck checked={grandChartsMyDayStore.filtersSubscriptions === FILTER_SUBSCRIPTIONS.ALL} />{" "}
					{t("filters.all")}
				</FilterItem>

				<FilterItem onClick={() => grandChartsMyDayStore.setFiltersSubscriptions(FILTER_SUBSCRIPTIONS.PURCHASED)}>
					<RadioCheck checked={grandChartsMyDayStore.filtersSubscriptions === FILTER_SUBSCRIPTIONS.PURCHASED} />{" "}
					{t("filters.purchased")}
				</FilterItem>
			</FilterBlock>

			<FilterBlock>
				<FilterTitle>{t("filters.instruments")}:</FilterTitle>

				<FilterItem onClick={() => grandChartsMyDayStore.setFiltersInstruments(FILTER_INSTRUMENTS.GUITAR)}>
					<RadioCheck checked={grandChartsMyDayStore.filtersInstruments.includes(FILTER_INSTRUMENTS.GUITAR)} />{" "}
					{t("instruments.guitar")}
				</FilterItem>

				<FilterItem onClick={() => grandChartsMyDayStore.setFiltersInstruments(FILTER_INSTRUMENTS.KEYBOARD)}>
					<RadioCheck checked={grandChartsMyDayStore.filtersInstruments.includes(FILTER_INSTRUMENTS.KEYBOARD)} />{" "}
					{t("instruments.keyboard")}
				</FilterItem>

				<FilterItem onClick={() => grandChartsMyDayStore.setFiltersInstruments(FILTER_INSTRUMENTS.SAXOPHONE)}>
					<RadioCheck checked={grandChartsMyDayStore.filtersInstruments.includes(FILTER_INSTRUMENTS.SAXOPHONE)} />
					{t("instruments.saxophone")}
				</FilterItem>
			</FilterBlock>

			<FilterBlock>
				<FilterTitle>{t("filters.level")}:</FilterTitle>

				<FilterItem
					onClick={() => grandChartsMyDayStore.setFiltersLevel(FILTER_LEVEL.SCHOOL)}
					color={getColorByService(ServiceName.School)}
				>
					<RadioCheck checked={grandChartsMyDayStore.filtersLevels.includes(FILTER_LEVEL.SCHOOL)} />
					{t("header.school")}
				</FilterItem>

				<FilterItem
					onClick={() => grandChartsMyDayStore.setFiltersLevel(FILTER_LEVEL.COLLEGE)}
					color={getColorByService(ServiceName.College)}
				>
					<RadioCheck checked={grandChartsMyDayStore.filtersLevels.includes(FILTER_LEVEL.COLLEGE)} />{" "}
					{t("header.college")}
				</FilterItem>

				<FilterItem
					onClick={() => grandChartsMyDayStore.setFiltersLevel(FILTER_LEVEL.UNIVERSITY)}
					color={getColorByService(ServiceName.University)}
				>
					<RadioCheck checked={grandChartsMyDayStore.filtersLevels.includes(FILTER_LEVEL.UNIVERSITY)} />{" "}
					{t("header.university")}
				</FilterItem>
			</FilterBlock>
		</FiltersContainer>
	);
});
