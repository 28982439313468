import * as React from "react";
import { DescriptionContainer } from "./Description.styled";
import { HTMLAttributes } from "react";

interface DescriptionProps extends HTMLAttributes<HTMLDivElement> {
	size?: "default" | "small" | "large";
	children?: React.ReactNode;
}

export const Description = ({ size = "default", children, ...props }: DescriptionProps) => {
	return (
		<DescriptionContainer {...props} size={size}>
			{children}
		</DescriptionContainer>
	);
};
