import { action, observable } from "mobx";
import { API } from "../../core";
import { CoreResponse } from "../../response";

export class BaseStore<I, C extends new (...args: any[]) => any> {
	public PATH = "";
	private IterationClass: C;

	@observable list: Array<InstanceType<C>> = [];

	constructor(iterationClass: C) {
		this.IterationClass = iterationClass;
	}

	@action.bound
	async getList() {
		try {
			const { data } = await API.request<CoreResponse<I[]>>(`${this.PATH}`);

			this.setList(data);
		} catch (e) {
			this.errorMessage(e);
		}
	}

	@action.bound
	setList(values: Array<I>) {
		this.list = values.map((item) => new this.IterationClass(item)) as Array<InstanceType<C>>;
	}

	errorMessage(e: unknown) {
		const nameClass = this.constructor.name;
		const nameMethod = Object.getOwnPropertyNames(BaseStore.prototype)?.[1];

		console.error(`Error in method ${nameClass}.${nameMethod}`);
	}
}
