import { LocalizationContainer, LocalizationCurrentLanguage } from "./Localization.styled";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useStores } from "../../../../../stores";
import { LanguageList } from "./LanguageList";

interface LocalizationProps {}

export const Localization = observer(({}: LocalizationProps) => {
	const { languagesStore, settingsStore } = useStores();

	const [show, setShow] = useState(false);

	useEffect(() => {
		(async () => {
			await languagesStore.getList();
		})();
	}, []);

	const handleOnShow = () => {
		console.log("handleOnShow", show);

		if (show) {
			return;
		}

		setShow(true);
	};

	const handleOnClose = () => {
		setShow(false);
	};

	return (
		<LocalizationContainer>
			<LocalizationCurrentLanguage isOpen={show} onClick={handleOnShow}>
				{settingsStore.language}
			</LocalizationCurrentLanguage>

			<LanguageList show={show} onClose={handleOnClose} />
		</LocalizationContainer>
	);
});
