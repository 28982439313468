import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../locales/en/translation.json";
import ru from "../locales/ru/translation.json";
import es from "../locales/es/translation.json";
import cn from "../locales/cn/translation.json";

// Инициализация i18next
i18n
	.use(initReactI18next) // Подключаем поддержку для React
	.init({
		resources: {
			en: {
				translation: en,
			},
			ru: {
				translation: ru,
			},
			es: {
				translation: es,
			},
			cn: {
				translation: cn,
			},
		},
		lng: "es", // Язык по умолчанию
		fallbackLng: "en", // Резервный язык
		interpolation: {
			escapeValue: false, // Важно: отключаем экранирование HTML
		},
	});

export default i18n;
