import styled, { css } from "styled-components";
import { COLORS, fontMixins } from "../../../../../styles";

export const LocalizationContainer = styled.div`
	position: relative;
	display: flex;
	z-index: 1000;
`;

interface LocalizationCurrentLanguageProps {
	isOpen: boolean;
}

export const LocalizationCurrentLanguage = styled.button<LocalizationCurrentLanguageProps>`
	background: transparent;
	display: flex;
	${fontMixins(16, 600)};
	border: 1px solid ${COLORS.White};
	border-radius: 10px;
	padding: 5px 10px;
	color: ${COLORS.Gold};
	cursor: pointer;
	user-select: none;
	transition: all 0.2s;

	${(props) =>
		props.isOpen &&
		css`
			background: ${COLORS.Gold};
			color: ${COLORS.White};
		`}
`;
