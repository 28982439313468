import * as React from "react";
import { DropDownContainer } from "./DropDown.styled";
import { MenuI } from "../../../../../../interfaces/menu/menu.interface";
import { HTMLAttributes } from "react";
import { DropDownItem } from "./DropDownItem";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

interface DropDownProps extends HTMLAttributes<HTMLDivElement> {
	isShow: boolean;
	items: MenuI[];
}

export const DropDown = observer(({ isShow, items }: DropDownProps) => {
	const { t } = useTranslation();

	return (
		<DropDownContainer show={isShow}>
			{items.map((item) => {
				item = {
					...item,
					...{
						name: t(`instruments.${item.name.toLowerCase()}`),
					},
				};

				return <DropDownItem key={item.link} item={item} />;
			})}
		</DropDownContainer>
	);
});
