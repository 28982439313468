import { action, computed, makeObservable, observable } from "mobx";
import { Score } from "../../interfaces/scores";
import { ScoreStore } from "./score.store";
import { ScoreViewStore } from "./score-view.store";

export class ScoresStore {
	@observable list: ScoreStore[] = [];
	@observable currentNumber = 0;
	@observable scoreView = new ScoreViewStore();

	constructor() {
		makeObservable(this);
	}

	@action.bound
	setList(values: Score[]) {
		const list = (values || []).map((score) => new ScoreStore(score));
		this.setCurrentNumber(0);
		this.list = [...list];
	}

	@action.bound
	setCurrentNumber(value: number) {
		if (value < 0 || value > this.total - 1) {
			return false;
		}

		this.currentNumber = value;
	}

	@computed
	get currentScore(): ScoreStore {
		return this.list[this.currentNumber] || new ScoreStore(null);
	}

	@computed
	get total() {
		return this.list.length;
	}

	@computed
	get isExistScoreItems() {
		return this.total > 0 && this.list[0].items.length > 0;
	}
}
