import * as React from "react";
import { useEffect } from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores";
import { HEADER_TYPE } from "../../constants";
import {
	Block,
	Blocks,
	Button,
	ContactPageContainer,
	Container,
	Description,
	FormWrapper,
	IconContainer,
	InputField,
	Title,
} from "./ContactPage.styled";
import { useFormik } from "formik";
import { ReactComponent as ICON_BACKGROUND_CONTACT } from "./contact_background.svg";
import { Helmet } from "react-helmet";
import { API } from "../../core";
import { gtmEvent } from "../../helpers";
import { useTranslation } from "react-i18next";

interface ContactPageProps {}

export const ContactPage = observer(({}: ContactPageProps) => {
	const { t } = useTranslation();

	const { settingsStore } = useStores();
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			name: "",
			email: "",
			questions: "",
		},
		onSubmit: async (values) => {
			try {
				await API.request(`contact`, {
					method: "post",
					body: API.getFormData(values),
				});

				gtmEvent({
					event: "sendForm_Victor",
					name: values.name.toLowerCase(),
					email: values.email.toLowerCase(),
					questions: values.questions.toLowerCase(),
				});

				formik.resetForm();
			} catch (e) {
				console.error(`Error in method onSubmit : `, e);
			}

			//formik.resetForm();
		},
	});

	useEffect(() => {
		settingsStore.setTypeHeader(HEADER_TYPE.RELATIVE);
		settingsStore.showFooter();
	}, []);

	return (
		<>
			<Helmet>
				<title>Contact | MUSICABINET</title>
				<meta
					name="description"
					content="Contact MUSICABINET. We love music and work to make music education accessible to everyone. Learn to play music instruments 5 times faster with our Advanced AI and Modular Education System. 4250+ Complete Lessons for Beginners, Advanced and Professionals."
				/>
			</Helmet>

			<ContactPageContainer>
				<Container>
					<Title>{t("pages.contact.title")}</Title>

					<Description>{t("pages.contact.description")}</Description>

					<Blocks>
						<Block>
							<FormWrapper onSubmit={formik.handleSubmit}>
								<InputField
									placeholder={t("pages.contact.form.name")}
									name={"name"}
									defaultValue={formik.values.name}
									onChange={formik.handleChange}
								/>

								<InputField
									placeholder={t("pages.contact.form.email")}
									name={"email"}
									defaultValue={formik.values.email}
									onChange={formik.handleChange}
								/>

								<InputField
									placeholder={t("pages.contact.form.question")}
									name={"questions"}
									defaultValue={formik.values.questions}
									onChange={formik.handleChange}
								/>
								<IconContainer>
									<ICON_BACKGROUND_CONTACT />
								</IconContainer>

								<Button type={"submit"}>Send</Button>
							</FormWrapper>
						</Block>

						<Block>
							<h4>{t("pages.contact.questions_title")}</h4>
							<p>{t("pages.contact.questions_description")}</p>
							<span>
								MUSICABINET - FZCO
								<br />
								Dubai Silicon Oasis, DDP, Building A1
								<br />
								Dubai, United Arab Emirates
								<br />
								<a href="mailto:support@musicabinet.com">support@musicabinet.com</a>
							</span>
						</Block>
					</Blocks>
				</Container>
			</ContactPageContainer>
		</>
	);
});
