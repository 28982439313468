import * as React from "react";
import { Container, ExtraInformation, InformationBlock } from "./BackgroundBlock.styled";
import { ButtonStartTrial, ImagePng } from "../../../components/common";
import { ImageJpg } from "../../../components/common/ImageJpg/ImageJpg";
import { useTranslation } from "react-i18next";

export const BackgroundBlock = () => {
	const { t } = useTranslation();

	return (
		<Container>
			<ImageJpg src={"/images/background/main"} />
			<InformationBlock>
				<ImagePng src={"/images/MC_logo_platform"} />
				<h1 dangerouslySetInnerHTML={{ __html: t("pages.main.background.pme") }} />
				<p dangerouslySetInnerHTML={{ __html: t("pages.main.background.pme_description") }} />

				<p dangerouslySetInnerHTML={{ __html: t("pages.main.background.pme_statistics") }} />
				<ButtonStartTrial style={{ paddingInline: 20 }} />

				<ExtraInformation dangerouslySetInnerHTML={{ __html: t("pages.main.background.pme_footer") }} />
			</InformationBlock>
		</Container>
	);
};
