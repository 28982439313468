import * as React from "react";
import { MenuContainer, MenuList } from "./Menu.styled";
import { MenuItem } from "./MenuItem";
import { observer } from "mobx-react";
import { useStores } from "../../../../../stores";
import { useTranslation } from "react-i18next";

interface MenuProps {}

export const Menu = observer(({}: MenuProps) => {
	const { t } = useTranslation();

	const { menuStore } = useStores();

	return (
		<MenuContainer>
			<MenuList>
				{menuStore.list.map((menu) => {
					// Модернизируем в зависимости от языка
					menu = {
						...menu,
						...{
							name: t(`header.${menu.name.toLowerCase()}`),
						},
					};

					return <MenuItem key={menu.link} {...menu} />;
				})}
			</MenuList>
		</MenuContainer>
	);
});
