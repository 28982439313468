import * as React from "react";
import { HeaderBreadCrumbs, HeaderContainer, HeaderLeftSide, HeaderRightSide } from "./Header.styled";
import { Logotype } from "./Logotype/Logotype";
import { Divider } from "./Divider/Divider";
import { Motto } from "./Motto/Motto";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { HEADER_TYPE, ServiceName } from "../../../../constants";
import { TrialMode } from "../../../common";
import { useParams } from "react-router-dom";
import { Menu } from "./Menu/Menu";
import { UserProfile } from "./UserProfile/UserProfile";
import { SignInButton } from "./SignInButton/SignInButton";
import { NotificationsAI } from "./Notifications";
import { Localization } from "./Localization";

export const Header = observer(() => {
	const { authStore, settingsStore, lessonStore } = useStores();
	const { uuid } = useParams();

	const mappingService = {
		[ServiceName.School]: "School",
		[ServiceName.College]: "College",
		[ServiceName.University]: "University",
		[ServiceName.Packs]: "Packs",
		[ServiceName.PreBeginner]: "Pre-Beginner",
		[ServiceName.PreAdvanced]: "Pre-Advanced",
		[ServiceName.PreProfessional]: "Pre-Professional",
		[ServiceName.SkillBundles]: "Skill Bundles",
	};

	const getBreadCrumbs = () => {
		return `${lessonStore.grand_chart_group_lesson.grand_chart_current.instrument.slug} ${
			mappingService[lessonStore.grand_chart_group_lesson.grand_chart_current.service.slug]
		} <span>/</span>  ${lessonStore.nameCourse} <span>/</span> ${lessonStore.nameModule} <span>/</span> ${
			lessonStore.nameLessonGroup
		} <span>/</span> ${lessonStore.name}`;
	};

	return (
		<>
			<HeaderContainer header_type={settingsStore.type_header}>
				<HeaderLeftSide>
					<Logotype />
					<Divider />

					{uuid && authStore.isAuth ? (
						<HeaderBreadCrumbs>
							<div dangerouslySetInnerHTML={{ __html: getBreadCrumbs() }}></div>
						</HeaderBreadCrumbs>
					) : (
						<Motto />
					)}
				</HeaderLeftSide>

				<HeaderRightSide>
					<Menu />
					<NotificationsAI />
					<Localization />
					<UserProfile />
					<SignInButton />
				</HeaderRightSide>
			</HeaderContainer>

			{settingsStore.type_header === HEADER_TYPE.RELATIVE && <TrialMode />}
		</>
	);
});
