import * as React from "react";
import {
	MetronomeBody,
	MetronomeButton,
	MetronomeButtons,
	MetronomeButtonStart,
	MetronomeButtonStartIcon,
	MetronomeContainer,
	MetronomeControls,
	MetronomeDivider,
	MetronomeHeader,
	MetronomeIconVolume,
	MetronomeInput,
	MetronomeVolume,
} from "./Metronome.styled";
import { Volume } from "../../../../components/common";
import { observer } from "mobx-react";
import { useStores } from "../../../../stores";
import { ICON_VOLUME_WITHOUT_VIBE_COMPONENT } from "../../../../components/common/Icons";
import { ReactComponent as IconMetronome } from "./metronome.svg";
import { useTranslation } from "react-i18next";

interface MetronomeProps {
	isHorizontal?: boolean;
}

export const Metronome = observer(({ isHorizontal = false }: MetronomeProps) => {
	const { t } = useTranslation();
	const { metronomeStore } = useStores();

	const handleOnChangeVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
		const volume = Number(e.currentTarget.value);
		metronomeStore.changeVolume(volume);
	};

	const getNameButton = () => {
		return metronomeStore.isPlay ? t("pages.lesson.stop") : t("pages.lesson.start");
	};

	const handleOnChangeBPM = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget;
		let newValue = Number(value);

		if (newValue > 300) {
			newValue = 300;
		}

		metronomeStore.setBPM(newValue);
	};

	const handleOnIncrement = () => {
		metronomeStore.setBPM(metronomeStore.current + 1);
	};

	const handleOnDecrement = () => {
		metronomeStore.setBPM(metronomeStore.current - 1);
	};

	const handleOnTrigger = () => {
		if (metronomeStore.isPlay) {
			metronomeStore.onStop();
		} else {
			metronomeStore.onStart();
		}
	};

	return (
		<MetronomeContainer isHorizontal={isHorizontal}>
			<MetronomeHeader>
				<span>{t("pages.lesson.metronome")}</span>
			</MetronomeHeader>

			<MetronomeDivider style={{ top: -1 }} />

			<MetronomeBody>
				<MetronomeControls>
					<MetronomeButtons>
						<MetronomeButton left minus onClick={handleOnDecrement}>
							<span />
						</MetronomeButton>
						<MetronomeInput
							type={"text"}
							value={metronomeStore.current}
							onChange={handleOnChangeBPM}
							maxLength={3}
							max={300}
						/>
						<MetronomeButton right onClick={handleOnIncrement}>
							<span>+</span>
						</MetronomeButton>
					</MetronomeButtons>

					<MetronomeButtonStart onClick={handleOnTrigger}>
						<MetronomeButtonStartIcon>
							<IconMetronome />
						</MetronomeButtonStartIcon>
						{getNameButton()}
					</MetronomeButtonStart>
				</MetronomeControls>

				<MetronomeVolume>
					<Volume
						defaultValue={metronomeStore.volume}
						name={"metronome-volume"}
						min={0}
						max={100}
						onChange={handleOnChangeVolume}
					/>

					<MetronomeIconVolume>
						<ICON_VOLUME_WITHOUT_VIBE_COMPONENT />
					</MetronomeIconVolume>
				</MetronomeVolume>
			</MetronomeBody>
		</MetronomeContainer>
	);
});
