import * as React from "react";
import { Container } from "./ForgotForm.styled";
import { ActionButton, Actions, Description, Form, Title } from "../../Modals/ForgotModal/ForgotModal.styled";
import { Button, InputField } from "../../../common";
import { ButtonTheme, SIGN_PAGE } from "../../../../constants";
import { useStores } from "../../../../stores";
import { FormEvent, useEffect } from "react";
import { useFormik } from "formik";
import { AuthForgotRequest } from "../../../../requests";
import { ForgotSchema } from "../../../../validation-schems";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

interface ForgotFormProps {
	onChangeForm: (value: SIGN_PAGE) => void;
}

export const ForgotForm = observer(({ onChangeForm }: ForgotFormProps) => {
	const { t } = useTranslation();

	const { authStore } = useStores();

	useEffect(() => {
		if (authStore.forgotForm.modal.isClose) {
			formik.resetForm();
		}
	}, [authStore.forgotForm.modal.isClose]);

	const handleOnSignUp = (e: FormEvent) => {
		e.preventDefault();
		onChangeForm(SIGN_PAGE.SIGN_UP);
	};

	const handleOnSignIn = (e: FormEvent) => {
		e.preventDefault();
		onChangeForm(SIGN_PAGE.SIGN_IN);
	};

	const formik = useFormik({
		initialValues: {
			email: "",
		},
		onSubmit: async (values) => {
			// Отправляем запрос
			await authStore.forgotPassword(values as AuthForgotRequest);
			// Закрываем окно
			authStore.forgotForm.modal.close();
		},
		validationSchema: ForgotSchema,
	});

	return (
		<Container>
			<Form onSubmit={formik.handleSubmit}>
				<InputField
					placeholder={"Your email"}
					type={"email"}
					name={"email"}
					value={formik.values.email}
					errorMessage={formik.errors.email}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
				/>
				<Button
					type={"submit"}
					disabled={!formik.dirty || formik.errors.email !== undefined || authStore.forgotForm.isLoading}
					isLoading={authStore.forgotForm.isLoading}
				>
					{t("buttons.reset_password")}
				</Button>
			</Form>

			<Actions>
				<ActionButton>
					<Description>{t("messages.not_account")}</Description>
					<Button theme={ButtonTheme.LINK_LARGE} style={{ marginTop: 5 }} onClick={handleOnSignUp}>
						{t("buttons.sign_up")}
					</Button>
				</ActionButton>
				<ActionButton>
					<Description>{t("messages.exist_account")}</Description>
					<Button theme={ButtonTheme.LINK_LARGE} style={{ marginTop: 5 }} onClick={handleOnSignIn}>
						{t("buttons.sign_in")}
					</Button>
				</ActionButton>
			</Actions>
		</Container>
	);
});
