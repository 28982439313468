import { action, computed, makeObservable, observable } from "mobx";
import {
	GrandChart,
	GrandChartAccess,
	GrandChartGroupLessonMapCompleteI,
	GrandChartLessonProgressI,
	Vimeo,
} from "../../interfaces";
import dayjs from "dayjs";
import { ServiceStore } from "../services/service.store";
import { InstrumentStore } from "../instruments/instrument.store";
import { GrandChartCourseStore } from "./grand-chart-course.store";
import { GrandChartModuleStore } from "./grand-chart-module.store";
import { GrandChartGroupLessonStore } from "./grand-chart-group-lesson.store";
import { ErrorAPI } from "../../helpers";
import { API } from "../../core";
import { ModalStore } from "../common";
import { GoodStore } from "../goods";
import { PriceDuration } from "../../constants";
import { GrandChartUnitedStore } from "./grand-chart-united.store";

export class GrandChartStore implements GrandChart {
	PATH = "grand-charts";

	@observable isLoading = false;

	@observable id = 0;
	@observable sort = 0;
	@observable service_id = 0;
	@observable instrument_id = 0;
	@observable title = "";
	@observable video_url = "";
	@observable description = "";
	@observable is_active = false;
	@observable uniting = false;
	@observable created_at = dayjs();
	@observable updated_at = dayjs();
	@observable access = GrandChartAccess.DENIED;
	@observable service: ServiceStore = new ServiceStore(null);
	@observable instrument: InstrumentStore = new InstrumentStore(null);
	@observable grand_chart_courses: GrandChartCourseStore[] = [];
	@observable grand_chart_modules: GrandChartModuleStore[] = [];
	@observable group_lessons_map: GrandChartGroupLessonMapCompleteI = {};
	@observable lesson_progress: GrandChartLessonProgressI = {};
	@observable goods: GoodStore[] = [];
	@observable grand_chart_uniteds: GrandChartUnitedStore[] = [];

	@observable selectedGrandChartGroupLesson = new GrandChartGroupLessonStore(null);
	@observable showGroupLesson = false;
	@observable vimeoBlock: null | Vimeo = null;

	@observable cancelModal = new ModalStore();
	@observable choosePlanModal = new ModalStore();

	constructor(initialData: GrandChart | null) {
		makeObservable(this);

		if (initialData) {
			this.fillStore(initialData);
		}
	}

	@action.bound
	setId(value: number) {
		this.id = value;
	}

	@action.bound
	setIsLoading(value: boolean) {
		this.isLoading = value;
	}

	@action.bound
	setShowGroupLesson(value: boolean) {
		this.showGroupLesson = value;
	}

	@action.bound
	async get() {
		if (!this.id) {
			return;
		}

		this.setIsLoading(true);
		try {
			const data = await API.request<GrandChart>(`${this.PATH}/${this.id}`);
			this.fillStore(data);
		} catch (e) {
			ErrorAPI("GrandChartStore get", e);
		} finally {
			this.setIsLoading(false);
		}
	}

	@action.bound
	async getById(id: number) {
		this.setIsLoading(true);
		try {
			const data = await API.request<GrandChart>(`${this.PATH}/${id}`);
			this.fillStore(data);
		} catch (e) {
			ErrorAPI("GrandChartStore getById", e);
		} finally {
			this.setIsLoading(false);
		}
	}

	@action.bound
	setSelectedGrandChartGroupLesson(value: GrandChartGroupLessonStore) {
		this.selectedGrandChartGroupLesson = value;
	}

	@action.bound
	sortClearSelectedGrandChartGroupLesson() {
		this.selectedGrandChartGroupLesson.clearID();
	}

	@action.bound
	clearSelectedGrandChartGroupLesson() {
		this.selectedGrandChartGroupLesson = new GrandChartGroupLessonStore(null);
	}

	@action.bound
	async getVideo() {
		try {
			const response = await fetch(`https://vimeo.com/api/oembed.json?url=${this.video_url}&width=700&height=356`);
			const result = await response.json();

			this.vimeoBlock = result as Vimeo;
		} catch (e) {
			console.error(`Error in method getVideo : `, e);
		}
	}

	@computed
	get widthSizeContainer(): string {
		const plusWidth = navigator.platform === "Win32" ? 30 : 0;

		return `${195 + this.grand_chart_modules.length * 220 + plusWidth + (this.grand_chart_modules.length - 1) * 10}px`;
	}

	@computed
	get heightSizeContainer(): number {
		console.log("count course : ", this.grand_chart_courses.length);

		// 170 + 170 * nCourse
		return 106 + 250 * this.grand_chart_courses.length + 20 * this.grand_chart_courses.length;
	}

	@computed
	get isExistVideo(): boolean {
		return this.video_url.length > 0;
	}

	@computed
	get grandChartUniteds() {
		return this.grand_chart_uniteds.map((item) => item.grand_chart.title).join(", ");
	}

	getPrice(duration_id: PriceDuration) {
		return computed(() => this.goods.find((good) => good.price_plan.duration_id === duration_id)).get();
	}

	@action
	fillStore(data: GrandChart) {
		const {
			id,
			sort,
			service_id,
			instrument_id,
			title,
			video_url,
			description,
			is_active,
			uniting,
			created_at,
			updated_at,
			access,
			service,
			instrument,
			grand_chart_modules,
			grand_chart_courses,
			group_lessons_map,
			lesson_progress,
			goods,
			grand_chart_uniteds,
		} = data;

		let completeMap: GrandChartGroupLessonMapCompleteI = {};

		if (group_lessons_map && Object.keys(group_lessons_map).length > 0) {
			Object.keys(group_lessons_map).forEach((key) => {
				completeMap[key] = group_lessons_map[key].map((groupLesson) => new GrandChartGroupLessonStore(groupLesson));
			});
		}

		this.id = id;
		this.sort = sort;
		this.service_id = service_id;
		this.instrument_id = instrument_id;
		this.title = title;
		this.video_url = video_url;
		this.description = description;
		this.is_active = is_active;
		this.uniting = uniting;
		this.created_at = dayjs(created_at);
		this.updated_at = dayjs(updated_at);
		this.access = access;
		this.service = new ServiceStore(service);
		this.instrument = new InstrumentStore(instrument);
		this.grand_chart_modules = (grand_chart_modules || []).map(
			(grand_chart_module) => new GrandChartModuleStore(grand_chart_module),
		);
		this.grand_chart_courses = (grand_chart_courses || []).map(
			(grand_chart_course) => new GrandChartCourseStore(grand_chart_course),
		);
		this.group_lessons_map = completeMap;
		this.lesson_progress = lesson_progress || {};
		this.goods = (goods || []).map((good) => new GoodStore(good));
		this.grand_chart_uniteds = (grand_chart_uniteds || []).map(
			(grand_chart_united) => new GrandChartUnitedStore(grand_chart_united),
		);
	}
}
