import * as React from "react";
import { Container, ItemInstrument, NameInstrument } from "./InstrumentList.styled";
import {
	ICON_INSTRUMENT_GUITAR,
	ICON_INSTRUMENT_KEYBOARD,
	ICON_INSTRUMENT_SAXOPHONE,
} from "../../../../components/common/Icons";
import { useTranslation } from "react-i18next";

interface InstrumentListProps {}

export const InstrumentList = ({}: InstrumentListProps) => {
	const { t } = useTranslation();

	return (
		<Container>
			<ItemInstrument>
				<ICON_INSTRUMENT_KEYBOARD />
				<NameInstrument>{t("instruments.keyboard")}</NameInstrument>
			</ItemInstrument>
			<ItemInstrument>
				<ICON_INSTRUMENT_GUITAR />
				<NameInstrument>{t("instruments.guitar")}</NameInstrument>
			</ItemInstrument>
			<ItemInstrument>
				<ICON_INSTRUMENT_SAXOPHONE />
				<NameInstrument>{t("instruments.saxophone")}</NameInstrument>
			</ItemInstrument>
		</Container>
	);
};
